<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
        <b-button
          class="is-primary is-pulled-right"
          @click="add()"
          icon-left="plus"
        >
          ເພີ່ມໃໝ່
        </b-button>
      </h1>
      <card-component class="has-table has-mobile-sort-spaced">
        <div class="columns panel" style="padding: 1em 1em 0.5em">
          <div class="column">
            <b-field label="ຈາກວັນທີ" horizontal>
              <b-datepicker
                v-model="fromdate"
                icon="calendar-today"
                @input="changeFromDate"
                :locale="this.getDateLocale"
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field label="ຫາວັນທີ" horizontal>
              <b-datepicker
                v-model="todate"
                icon="calendar-today"
                @input="changeTodate"
                :locale="this.getDateLocale"
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-2">
            <b-button class="is-info" @click="search()" icon-left="magnify">
            </b-button>
          </div>
        </div>
        <b-table
          :loading="isLoading"
          :paginated="perPage < models.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          :data="models"
          :current-page="currentPage"
          :pagination-rounded="true"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index * currentPage + 1 }}
          </b-table-column>
          <b-table-column
            label="ວັນທີ"
            field="created_date"
            sortable
            v-slot="props"
          >
            {{ props.row.created_date | datetime }}
          </b-table-column>
          <b-table-column label="ລະຫັດ" field="code" sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column
            label="ຜູ້ສົ່ງ"
            field="sendername"
            sortable
            v-slot="props"
          >
            {{ props.row.sendername }}
          </b-table-column>
          <b-table-column
            label="ຜູ້ຮັບ"
            field="receivername"
            sortable
            v-slot="props"
          >
            {{ props.row.receivername }}
          </b-table-column>
          <b-table-column
            label="ສາຂາຕົ້ນທາງ"
            field="receivername"
            sortable
            v-slot="props"
          >
            {{ props.row.branch_branchToshipment_from_branch_id.name }}
          </b-table-column>
          <b-table-column
            label="ສາຂາປາຍທາງ"
            field="receivername"
            sortable
            v-slot="props"
          >
            {{ props.row.branch_branchToshipment_to_branch_id.name }}
          </b-table-column>
          <b-table-column
            label="ຈຳນວນເງິນ"
            field="amount"
            sortable
            v-slot="props"
            cell-class="has-text-right"
          >
            {{ props.row.amount | formatnumber }}
          </b-table-column>
          <b-table-column
            label="ສະຖານະ"
            sortable
            v-slot="props"
            cell-class="has-text-centered"
          >
            <shipment-status :status="props.row.status" />
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="props"
          >
            <div class="buttons is-right">
              <b-button
                class="is-small is-info"
                @click="view(props.row)"
                icon-left="eye"
              >
              </b-button>
              <b-button
                class="is-small is-warning"
                @click="edit(props.row)"
                icon-left="pencil"
              >
              </b-button>
              <b-button
                v-if="false"
                class="is-small is-danger"
                @click="remove(props.row)"
                icon-left="trash-can"
              >
              </b-button>
            </div>
          </b-table-column>

          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>

    <div class="modal is-active is-large" v-if="isShowModal && model">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="ລະຫັດສາຂາ" horizontal>
            <b-input v-model="model.code" placeholder="ລະຫັດສາຂາ" required />
          </b-field>
          <b-field label="ຊື່ສາຂາ" horizontal>
            <b-input v-model="model.name" placeholder="ຊື່ສາຂາ" required />
          </b-field>
          <b-field label="ເບີໂທ" horizontal>
            <b-input v-model="model.tel" placeholder="ເບີໂທ" required />
          </b-field>
          <b-field label="ປະເພດ" class="has-check" horizontal>
            <radio-picker
              v-model="model.type"
              :options="{
                COD: 'COD',
                DESTINATION: 'ປາຍທາງ',
                CODDESTINATION: 'ປາຍທາງ + COD',
              }"
            />
          </b-field>
          <b-field label="ແຂວງເມືອງບ້ານ" horizontal>
            <b-select v-model="model.province" placeholder="ແຂວງ" required>
              <option
                v-for="(province, index) in provinces"
                :key="index"
                :value="province"
              >
                {{ province.name }}
              </option>
            </b-select>
            <b-select v-model="model.district" placeholder="ເມືອງ" required>
              <option
                v-for="(district, index) in getDistricts()"
                :key="index"
                :value="district"
              >
                {{ district.name }}
              </option>
            </b-select>
            <b-input v-model="model.village" placeholder="ບ້ານ" required />
          </b-field>
          <b-field label="ສະຖານະ" horizontal>
            <radio-picker
              v-model="model.status"
              :options="{
                ACTIVE: 'ເປີດ',
                INACTIVE: 'ປິດ',
              }"
            />
          </b-field>
          <b-field label="ທີ່ຢູ່" horizontal>
            <b-input
              type="textarea"
              :rows="2"
              v-model="model.address"
              placeholder="ທີ່ຢູ່"
            />
          </b-field>

          <GmapMap
            :center="center"
            :zoom="15"
            map-type-id="terrain"
            style="width: 100%; height: 300px"
            @click="onClickMap"
          >
            <GmapMarker
              :position="position"
              :clickable="true"
              :draggable="true"
            />
          </GmapMap>
        </section>
        <footer class="modal-card-foot">
          <b-button
            class="is-success"
            slot="right"
            @click="save()"
            icon-left="check"
          >
            ບັນທຶກ
          </b-button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { get } from "vuex-pathify";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
import RadioPicker from "@/components/RadioPicker";
import ShipmentStatus from "../components/ShipmentStatus.vue";
import moment from "moment";

export default {
  name: "Shipments",
  components: { CardComponent, EmptyTable, RadioPicker, ShipmentStatus },
  computed: {
    user: get("user"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ການຂົນສົ່ງ",
      model: null,
      models: [],
      isShowModal: false,
      isLoading: false,
      center: { lat: 17.979454, lng: 102.638114 },
      position: { lat: 17.979454, lng: 102.638114 },
      provinces: null,
      districts: null,
      fromdate: null,
      todate: null,
    };
  },
  mounted() {
    this.isLoading = true;
    this.fromdate = new Date();
    this.fromdate.setDate(this.fromdate.getDate() - 1);
    this.todate = new Date();
    axios.get("provinces").then((res) => {
      this.provinces = res;
    });
    axios.get("districts").then((res) => {
      this.alldistricts = res;
    });
    this.search();
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  methods: {
    search() {
      this.isLoading = true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      axios.get(`shipments/getsbydate/${fdate}/${tdate}`).then((r) => {
        this.models = r;
      });
    },
    add() {
      this.$router.push({ path: "/addshipment" });
    },
    edit(item) {
      this.$router.push({ path: "/updateshipment/" + item.code });
    },
    view(item) {
      this.$router.push({ path: "/shipment/" + item.code });
    },
    changeFromDate(e) {
      this.fromdate = e;
    },
    changeTodate(e) {
      this.todate = e;
    },
  },
};
</script>